<template>
  <svg style="width: 70px; height: 70px" viewBox="0 0 70 70">
    <path
      fill="none"
      :stroke-width="weight"
      :stroke="color"
      :d="`M ${size / 2 + radius},${
        size / 2
      } A ${radius},${radius} 0 ${largeArcFlag},1 ${x},${y}`"
    ></path>
    <path
      fill="none"
      :stroke-width="weight"
      :stroke="fadeColor"
      :d="`M ${size / 2 + radius},${
        size / 2
      } A ${radius},${radius} 0 ${fadeLargeArcFlag},0 ${x},${y}`"
    ></path>
  </svg>
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
      default: 50,
    },
    count: {
      type: Number,
      default: 25,
    },
    color: {
      type: String,
      default: "yellow",
    },
    //底色
    fadeColor: {
      type: String,
      default: "yellow",
    },
    // 半径
    radius: {
      type: Number,
      default: 30,
    },
    // 宽高
    size: {
      type: Number,
      default: 70,
    },
    // 线条粗细
    weight: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      //   大弧(1) 小弧(0)
      largeArcFlag: 0,
      fadeLargeArcFlag: 1,
      //   结束点x
      x: 0,
      // 结束点y
      y: 0,
    };
  },
  methods: {
    //   计算结束点位置
    calc() {
      //大弧
      this.largeArcFlag = this.count * 2 > this.total ? 1 : 0;
      //小弧
      this.fadeLargeArcFlag = this.count * 2 > this.total ? 0 : 1;
      let angle = (this.count / this.total) * Math.PI * 2;
      let radius = this.radius;
      let center = this.size / 2;
      if (this.count * 4 < this.total) {
        this.x = radius * Math.cos(angle) + center;
        this.y = radius * Math.sin(angle) + center;
        return;
      }
      if (this.count * 4 == this.total) {
        this.x = center;
        this.y = radius + center;
        return;
      }
      if (this.count * 2 < this.total) {
        this.x = center - radius * Math.cos(Math.PI - angle);
        this.y = center + radius * Math.sin(Math.PI - angle);
        return;
      }
      if (this.count * 2 == this.total) {
        this.x = center - radius;
        this.y = center;
        return;
      }
      if (this.count * 4 < this.total * 3) {
        this.x = center - radius * Math.cos(Math.PI + Math.PI / 2 - angle);
        this.y = center - radius * Math.sin(Math.PI + Math.PI / 2 - angle);
        return;
      }
      if (this.count * 4 == this.total * 3) {
        this.x = center;
        this.y = center - radius;
        return;
      }
      if (this.count < this.total) {
        this.x = 30 * Math.cos(Math.PI * 2 - angle) + center;
        this.y = center - radius * Math.sin(Math.PI * 2 - angle);
        return;
      }
      if (this.count == this.total) {
        this.x = center + radius;
        this.y = center ;
        return;
      }
    },
  },
  mounted() {
    this.calc();
  },
};
</script>
<style lang='less' scoped>
</style>