<template>
  <div class="container">
    <div>
      <Row :gutter="16">
        <Col span="6">
          <Card shadow style="height: 450px">
            <div style="display: flex; justify-content: space-between">
              <p style="line-height: 31px; font-size: 17px; font-weight: 600">
                选择养殖场
              </p>
              <!-- <Select v-model="model1" style="width: 200px">
                <Option
                  v-for="item in farmList"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select> -->
              <Input
                v-model="farmName"
                placeholder="请选择养殖场"
                style="width: 200px"
                @on-focus="selFarm"
              ></Input>
            </div>
            <div style="display: flex; line-height: 31px">
              <p>所属企业：</p>
              <span class="listItem">{{ form.companyName }}</span>
            </div>
            <div style="display: flex; line-height: 31px">
              <p>养殖场名称：</p>
              <span class="listItem">{{ form.name }}</span>
            </div>
            <div style="display: flex; line-height: 31px">
              <p>养殖场面积：</p>
              <span class="listItem">{{ form.area }}</span>
            </div>
            <div style="display: flex; line-height: 31px">
              <p>负责人：</p>
              <span class="listItem">{{ form.chargePerson }}</span>
            </div>
            <div style="display: flex; line-height: 31px">
              <p>联系电话：</p>
              <span class="listItem">{{ form.chargePersonTel }}</span>
            </div>
            <div style="display: flex; line-height: 31px">
              <p>所属区域：</p>
              <span class="listItem">{{ form.regionCodeName }}</span>
            </div>
            <div style="display: flex; line-height: 31px">
              <p>粪污处理工艺流程：</p>
              <span class="listItem">{{ form.craftName }}</span>
            </div>
            <div
              id="demoCanvas1"
              style="width: 100%; height: 160px; background-color: #eee"
              v-if="servicePath"
            ></div>
            <img
              src="/imgs/empty/empty.png"
              alt=""
              v-else
              style="width: 100%; height: 160px"
            />
          </Card>
        </Col>
        <!-- <Col span="1"></Col> -->
        <Col span="18">
          <Row :gutter="16">
            <Col span="8">
              <Card shadow>
                <Row>
                  <Col span="12">
                    <img src="/imgs/farm/base.png" alt="" />
                  </Col>

                  <Col class="cardItem">
                    <p style="color: #36c5db; font-size: 34px">{{ parseInt(dayNum) }}</p>
                    <p>粪污产量(kg)</p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span="8">
              <Card shadow>
                <Row :gutter="16">
                  <Col span="12">
                    <img src="/imgs/farm/device.png" alt="" />
                  </Col>

                  <Col class="cardItem">
                    <p style="color: #54b8f9; font-size: 34px">
                      {{ equipmentNum }}
                    </p>
                    <p>设备设施(个)</p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span="8">
              <Card shadow>
                <Row :gutter="16">
                  <Col span="12">
                    <img src="/imgs/farm/inventory.png" alt="" />
                  </Col>

                  <Col class="cardItem">
                    <p style="color: #54b8f9; font-size: 34px">
                      {{ pigStock }}
                    </p>
                    <p>存栏(头)</p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row :gutter="16">
            <Col span="16">
              <Card style="height: 318px; margin-top: 20px">
                <h3>生产利用</h3>
                <div class="circle">
                  <div>
                    <i-circle
                      :percent="50"
                      stroke-color="#7A40F3"
                      :size="180"
                      style="margin: 20px 30px"
                    >
                      <span
                        class="demo-i-circle-inner"
                        style="font-size: 40px; color: #7a40f3"
                        >{{
                          productManure.length > 0
                            ? productManure[0].content +
                              productManure[0].contentUnit
                            : "0%"
                        }}</span
                      >
                    </i-circle>
                    <p style="font-size: 22px">处理能力</p>
                  </div>
                  <div>
                    <i-circle
                      :percent="80"
                      stroke-color="#3ACBE9"
                      :size="180"
                      style="margin: 20px 0"
                    >
                      <span
                        class="demo-i-circle-inner"
                        style="font-size: 40px; color: #3acbe9"
                        >{{
                          productManure.length > 0
                            ? productManure[1].content +
                              productManure[1].contentUnit
                            : "0%"
                        }}</span
                      >
                    </i-circle>
                    <p style="font-size: 22px">设备产能配套率</p>
                  </div>
                  <div>
                    <i-circle
                      :percent="30"
                      stroke-color="#FF9262"
                      :size="180"
                      style="margin: 20px 40px"
                    >
                      <span
                        class="demo-i-circle-inner"
                        style="font-size: 40px; color: #ff9262"
                        >{{
                          productManure.length > 0
                            ? productManure[2].content +
                              productManure[2].contentUnit
                            : "0%"
                        }}</span
                      >
                    </i-circle>
                    <p style="font-size: 22px">资源化利用率</p>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span="8">
              <Card style="height: 318px; margin-top: 20px">
                <Row :gutter="16">
                  <Col
                    span="8"
                    style="
                      margin: 0 8px 0 0;
                      font-size: 17px;
                      font-weight: 700;
                      line-height: 31px;
                    "
                    >监控视频</Col
                  >
                  <Col>
                    <Select v-model="vidoId" style="width: 180px">
                      <Option
                        v-for="item in videoList"
                        :value="item.id"
                        :key="item.id"
                        >{{ item.name }}</Option
                      >
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div class="video-play" v-if="videoList.length > 0">
                      <player :videoInfo="videoInfo"></player>
                    </div>
                    <img
                      v-else
                      src="/imgs/empty/empty.png"
                      alt=""
                      style="width: 100%; margin-top: 20px"
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <div style="margin-top: 20px">
        <Row :gutter="14">
          <Col span="18">
            <Card>
              <Row>
                <Col span="15">
                  <Select v-model="model" style="width: 200px">
                    <Option
                      v-for="item in cityList1"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </Col>
                <Col span="3">
                  <div class="filter-box">
                    <span
                      :class="[
                        'filter-item',
                        fertilizeType == 'day' ? 'filter-item-active' : '',
                      ]"
                      @click="() => monitorCut('day')"
                      >日</span
                    >
                    <!-- <span
                      :class="[
                        'filter-item',
                        fertilizeType == 'week' ? 'filter-item-active' : '',
                      ]"
                      @click="() => monitorCut('week')"
                      >本周</span
                    > -->
                    <span
                      :class="[
                        'filter-item',
                        fertilizeType == 'month' ? 'filter-item-active' : '',
                      ]"
                      @click="() => monitorCut('month')"
                      >月</span
                    >
                    <span
                      :class="[
                        'filter-item',
                        fertilizeType == 'year' ? 'filter-item-active' : '',
                      ]"
                      @click="() => monitorCut('year')"
                      >年</span
                    >
                  </div>
                </Col>
                <Col span="6" push="1">
                  <DatePicker
                    type="daterange"
                    placement="bottom-end"
                    placeholder="选择日期"
                    style="width: 200px"
                    @on-change="dateChange"
                    v-model="time"
                  ></DatePicker>
                </Col>
                <Col span="24">
                  <div id="myChart" style="width: 100%; height: 280px"></div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span="6" style="height: 346px">
            <Card style="height: 100%">
              <Row>
                <Col span="24">
                  <h3>预警消息</h3>
                </Col>
                <Col span="24">
                  <template v-if="alarm.length > 0">
                    <Row v-for="(item, index) in alarm" :key="index">
                      <Col span="24">{{ item.content }}</Col>
                      <Col span="20" style="color: #ccc">{{
                        item.createTime
                      }}</Col>
                      <!-- <Col span="4">未读</Col> -->
                    </Row>
                  </template>
                  <div
                    v-else
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                      margin-top: 80px;
                    "
                  >
                    <img style="width: 120px;height: 90px;" src="/imgs/empty/empty.png" alt="" />
                    <p>暂无预警信息</p>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>

    <select-farm
      v-model="selModal"
      :farmId="model1"
      :options="options"
      isDefault
      @sel-finish="selFinish"
    ></select-farm>
  </div>
</template>

<script>
import * as echarts from "echarts";
import * as SVGA from "svgaplayerweb";
import player from "@/components/player";
import moment from "moment";
import circleAround from "@/components/circleAround";
import selectFarm from "@/components/selectFarm";
export default {
  components: { player, circleAround, selectFarm },
  data() {
    return {
      fertilizeType: "day",
      monitorType: "year",
      model1: "",
      cityList: [],
      farmList: [],
      breedingNum: "",
      vidoId: "",
      videoList: [],
      videoInfo: null,
      //x轴data
      data: [],
      //y轴data
      useAmountData: [],
      cityList1: [
        { value: "1", label: "粪污生产" },
        { value: "2", label: "粪污施用" },
        { value: "3", label: "粪污转运" },
      ],
      time: [],
      model: "1",
      //存栏数
      pigStock: 0,
      //设备设施
      equipmentNum: 0,
      //当日数据
      dayNum: 0,
      form: {},
      //svga图片地址
      servicePath: "",
      farmId: "",
      farmName: "",
      selModal: false,
      options: {
        siteType: 2,
      },
      productManure: [],
      alarm: [],
    };
  },
  created() {
    this.time.push(moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"));
    this.time.push(moment().format("YYYY-MM-DD"));
  },
  watch: {
    //监听echarts下拉
    model1(n) {
      if (!n) return;
      this.model1 = n;
      this.getBreeding(n);
      this.getFacility(n);
      this.getList();
      this.getEchartsData();
      this.getVideo();
      this.getProductManure();
      this.getAlarm();
      this.getDay()
      if (this.form.craft != "") {
        this.getCraft();
      }
    },
    farmList(n) {
      this.getEchartsData();
    },
    videoId(n) {
      this.videoInfo = this.videoList.filter((item) => item.id == n)[0];
    },
    //监听养殖场下拉
    model(n) {
      this.getEchartsData(n);
    },
    //切换视频
    vidoId(n) {
      this.videoInfo = this.videoList.filter((item) => item.id == n)[0];
    },
  },
  methods: {
    selFarm() {
      this.selModal = true;
    },
    selFinish(farm) {
      this.model1 = farm.id;
      this.farmName = farm.name;
      this.form = farm;
      this.selModal = false;
    },
    //初始化svga
    initSvga() {
      let url = this.servicePath;
      let player = new SVGA.Player(`#demoCanvas1`);
      let parser = new SVGA.Parser(`#demoCanvas1`); // Must Provide same selector eg:#demoCanvas IF support IE6+
      parser.load(url, function (videoItem) {
        player.setVideoItem(videoItem);
        player.startAnimation();
      });
    },
    //初始化echarts
    initEcharts() {
      let myChart = echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        grid: {
          top: 20,
          left: 50,
          right: 50,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: this.data,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6",
            },
          },
        },
        color: ["#7A40F2"],
        series: {
          type: "line",
          smooth: true,
          data: this.useAmountData,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(122, 64, 242, 0.4)",
                },
                {
                  offset: 1,
                  color: "rgba(122, 64, 242, 0)",
                },
              ],
            },
          },
          lineStyle: {
            shadowOffsetY: 10,
            shadowColor: "rgba(122, 64, 242, 0.3)",
            shadowBlur: 4,
          },
        },
      });
    },
    monitorCut(type) {
      if (this.fertilizeType == type) return;
      this.fertilizeType = type;
      if (this.fertilizeType == "day") {
        this.time = [];
        this.time.push(
          moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
        );
        this.time.push(moment().format("YYYY-MM-DD"));
      }

      if (this.fertilizeType == "month") {
        this.time = [];
        this.time.push(
          moment(new Date()).subtract(60, "days").format("YYYY-MM-DD")
        );
        this.time.push(moment().format("YYYY-MM-DD"));
        this.$forceUpdate();
        console.log(this.time);
      }
      if (this.fertilizeType == "year") {
        this.time = [];
        this.time.push(
          moment(new Date()).subtract(2, "year").format("YYYY-MM-DD")
        );
        this.time.push(moment().format("YYYY-MM-DD"));
      }
      this.getEchartsData();
    },
    //获取养殖场
    getList() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        userId: localStorage.getItem("userId"),
        companyNo: localStorage.getItem("companyNo"),
      }).then((res) => {
        this.farmList = res.list;
        if (this.model1 == "") {
          this.model1 = res.list[0].id;
        }
        res.list.forEach((item) => {
          this.farmId = item.id;
          if (this.model1 == item.id) {
            this.form = item;
          }
        });
        if (this.form.craft != "") {
          this.getCraft();
        }
      });
    },
    dateChange() {
      this.getEchartsData();
    },
    //存栏
    getBreeding(id) {
      this.$post(this.$api.FARM_STATISTICS.BREEDING, {
        farmId: id,
      }).then((res) => {
        this.pigStock = res;
      });
    },
    //设备设施
    getFacility(id) {
      this.$post(this.$api.FARM_STATISTICS.FACILITY, {
        farmId: id,
      }).then((res) => {
        this.equipmentNum = res;
      });
    },
    //当日数据
    getDay() {
      this.$post(this.$api.FARM_STATISTICS.DAYLIST, {
        farmId: this.model1.toString(),
      }).then((res) => {
        this.dayNum = res;
      });
    },
    getVideo() {
      this.$post(this.$api.BUSSDEVICEVIDEO.LIST, {
        farmId: this.model1,
      }).then((res) => {
        if (res.list.length == 0) {
          this.videoList = [];
          this.videoId = "";
          this.videoInfo = null;
          return;
        }
        this.videoList = res.list;
        this.videoId = res.list[0].id;
        this.vidoId = res.list[0].id;
        this.videoInfo = res.list[0];
      });
    },
    //Echarts数据
    getEchartsData(n) {
      if (this.time.length < 2 || !this.time[0] || !this.time[1]) return;
      if (!this.model1) return
      let url;
      if (this.model == "1") {
        url = this.$api.FARM_STATISTICS.FECESPD;
      } else if (this.model == "2") {
        url = this.$api.FARM_STATISTICS.FECESUSE;
      } else if (this.model == "3") {
        url = this.$api.FARM_STATISTICS.TRANSPORT;
      }
      this.$post(url, {
        type: this.fertilizeType,
        startTime: moment(this.time[0]).format("YYYY-MM-DD") + " 00:00:00",
        endTime: moment(this.time[1]).format("YYYY-MM-DD") + " 23:59:59",
        farmId: this.model1,
      }).then((res) => {
        this.data = Object.keys(res);
        this.useAmountData = Object.values(res);
        if (this.model == "3") {
          this.data = Object.keys(res.timeLine);
          this.useAmountData = Object.values(res.timeLine);
        }
        this.initEcharts();
      });
    },
    getCraft() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "261",
      }).then((res) => {
        res.list.forEach((item) => {
          if (this.form.craft == item.id) {
            this.servicePath = item.description;
          }
        });
        this.initSvga();
      });
    },
    getProductManure() {
      this.$post(this.$api.STATICS_MANAGE.GET2, {
        resourceTypeId: 153,
        farmId: this.model1,
      }).then((res) => {
        this.productManure = res;
      });
    },
    getAlarm() {
      this.$post(this.$api.PRODUCE_MAPINFO.WARNING, {
        farmId: this.model1,
        isAdmin: "1",
        pageNo: 1,
        pageSize: 4,
      }).then((res) => {
        console.log(res);
        this.alarm = res.list;
      });
    },
  },
  mounted() {
    // this.getList();
    this.initEcharts();
    this.initSvga();
    this.getDay();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
